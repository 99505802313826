<template>
    <div class="create-blog-article" v-if="form">
        <h2>{{ $t('blogArticles.post') }}</h2>
        <LangButtons class="lang-buttons" :locale="locale" @selectLocale="selectLocale" />
        <CRInput
            v-model="form.title[locale]"
            class="mb-20"
            required
            :label="$t('blogArticles.articleName')"
            :placeholder="$t('placeholder.enterArticleName')"
        />

        <ImagesUploadSection
            :title="$t('blogArticles.pictures')"
            :image-files="form.imageFiles"
            @makeThumbnail="makeThumbnail"
            @removePicture="removePicture"
            @handleUpload="handleUpload"
        />

        <CreationSection :title="$t('blogArticles.content')">
            <Wysiwyg
                v-model="form.content[locale]"
                :key="componentKey"
                contentType="html"
                theme="snow"
                toolbar="full"
                :borderRadius="20"
                :height="600"
            />
        </CreationSection>
        <RequiredText class="w-full" />

        <CRButton class="mt-40" @click="createPost">
            {{ isEditPage ? $t('buttons.update') : $t('buttons.create') }}
        </CRButton>
    </div>
</template>

<script>
    import RequiredText from '@/components/Common/Tags/RequiredText.vue';
    import ImagesUploadSection from '@/components/Common/ImagesUploadSection/ImagesUploadSection.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import Wysiwyg from '@/components/Common/Wysiwyg/Wysiwyg.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { langMixin, rerenderMixin } from '@/utils/mixins';
    import { handleUploadImage } from '@/helpers/UploadImageHelper';
    import {
        prepareInitialDataForPage,
        prepareDataForRequest,
        deleteBlogArticleImage,
        uploadBlogArticleImage,
        changeBlogArticleThumbnailImage,
        clearForm,
    } from '@/helpers/BlogArticleHelper';
    import { mapGetters } from 'vuex';
    import { BlogArticleApi } from '@/api';

    export default {
        name: 'CreateBlogArticle',
        components: { CRInput, Wysiwyg, CreationSection, CRButton, ImagesUploadSection, RequiredText },
        mixins: [langMixin, rerenderMixin],
        data() {
            return {
                form: null,

                routerName: '',
            };
        },
        computed: {
            ...mapGetters('blogArticle', ['currentBlog']),
            isEditPage() {
                return this.routerName === 'updateBlogArticle';
            },
        },
        watch: {
            locale() {
                this.componentKey += 1;
            },

            $route: {
                async handler(value) {
                    this.routerName = value.name;
                    try {
                        this.form = await prepareInitialDataForPage(this.isEditPage, this.currentBlog);
                    } catch (error) {
                        this.$filters.toast(error.message);
                    }
                },
                immediate: true,
            },
        },
        methods: {
            async createPost() {
                try {
                    this.isLoading = true;

                    if (this.isEditPage) {
                        await BlogArticleApi.update(
                            prepareDataForRequest(this.form, this.isEditPage),
                            this.$route.params.blog_article_id
                        );
                    } else {
                        await BlogArticleApi.store(prepareDataForRequest(this.form, this.isEditPage));
                    }
                    this.$router.push({ name: 'allAdminBlogArticles' });
                } catch (error) {
                    Object.entries(error.errors).forEach((element) => {
                        this.$filters.toast(element[1][0]);
                    });
                } finally {
                    this.isLoading = false;
                }
            },

            // Images Upload
            async removePicture({ value, id }) {
                try {
                    this.form.imageFiles = this.form.imageFiles.filter((item, index) => index !== value);

                    if (this.isEditPage) {
                        await deleteBlogArticleImage(id);
                    }
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async handleUpload(files) {
                try {
                    handleUploadImage(files, this.form.imageFiles);

                    if (this.isEditPage) {
                        this.form.imageFiles = await uploadBlogArticleImage(files, this.$route.params.blog_article_id);
                    }
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async makeThumbnail({ index, id }) {
                try {
                    this.form.imageFiles[0] = this.form.imageFiles.splice(index, 1, this.form.imageFiles[0])[0];

                    if (this.isEditPage) {
                        await changeBlogArticleThumbnailImage(id);
                    }
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        beforeRouteLeave(to, from, next) {
            this.form = clearForm(this.form);
            next();
        },
    };
</script>

<style lang="scss" scoped>
    .create-blog-article {
        padding-bottom: 30px;
        max-width: 700px;

        h2 {
            margin: 20px 0;
        }

        .lang-buttons {
            margin-bottom: 30px;
        }
    }

    @include media($lg) {
        .create-blog-article {
            h2 {
                font-size: $font-34;
                margin-bottom: 45px;
            }
        }
    }
</style>
