import { PictureApi } from '@/api';
import { cloneDeep } from 'lodash';

// For Admin
export const INITIAL_FORM = {
    title: {
        en: '',
        lt: '',
    },
    content: {
        en: '',
        lt: '',
    },
    imageFiles: [],
};

export const prepareInitialDataForPage = async (isEditPage, currentBlogArticle) => {
    if (isEditPage) {
        const { title, content, pictures } = currentBlogArticle;

        let form = {
            title,
            content,
            imageFiles: pictures,
        };

        return form;
    } else {
        return cloneDeep(INITIAL_FORM);
    }
};

export const prepareDataForRequest = (form, isEditPage) => {
    let formData = new FormData();

    form.imageFiles.forEach((element) => {
        formData.append('pictures[]', element);
    });

    let payload = {
        title: form.title,
        content: form.content,
    };

    formData.append('payload', JSON.stringify(payload));

    if (isEditPage) {
        return payload;
    } else {
        return formData;
    }
};

export const uploadBlogArticleImage = async (images, blogId) => {
    let formData = new FormData();

    Array.from(images).forEach((element) => {
        formData.append('pictures[]', element);
    });

    formData.append('picturable_type', 'blog_article');
    formData.append('picturable_id', blogId);

    const response = await PictureApi.store(formData);

    return response.data;
};

export const deleteBlogArticleImage = async (id) => {
    await PictureApi.destroy(id);
};

export const changeBlogArticleThumbnailImage = async (id) => {
    await PictureApi.makeThumbnail(id);
};

export const clearForm = (form) => {
    Object.entries(form).forEach(([key, val]) => {
        form = cloneDeep(INITIAL_FORM);

        if (Array.isArray(val)) {
            val.length = 0;
            form[key] = val;
        }
    });

    return form;
};
