<template>
    <CreationSection :title="title" class="image__wrapper">
        <CRButton>
            <input type="file" @change="handleUpload($event)" multiple />
            {{ $t('buttons.addImage') }}
        </CRButton>
        <div class="upload-block" :class="{ empty: !imageFiles.length }">
            <input type="file" @change="handleUpload($event)" multiple />
            <h3 v-if="!imageFiles.length">
                {{ $t('mealKits.noImagesAdded') }}
            </h3>
            <ImageCard
                v-for="(image, index) in imageFiles"
                :key="index"
                :index="index"
                :image="image"
                @removePicture="removePicture"
                @makeThumbnail="makeThumbnail"
            />
        </div>
    </CreationSection>
</template>

<script>
    import ImageCard from '@/components/Common/ImagesUploadSection/ImageCard.vue';
    import CreationSection from '@/components/Admin/MealKits/CreationSection.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { backgroundStyles } from '@/helpers/CssHelper';

    export default {
        name: 'ImagesUploadSection',
        components: { CreationSection, CRButton, ImageCard },
        props: {
            title: {
                type: String,
                default: '',
            },
            imageFiles: {
                type: Array,
                default: () => [],
            },
        },
        emits: ['handleUpload', 'makeThumbnail', 'removePicture'],
        data() {
            return {
                backgroundStyles,
            };
        },
        methods: {
            async handleUpload($event) {
                this.$emit('handleUpload', $event.target.files);
            },

            async removePicture({ value, id }) {
                this.$emit('removePicture', { value, id });
            },

            renderImage(file) {
                return file?.id ? file.picturePath.inner : window.URL.createObjectURL(file);
            },

            async makeThumbnail({ index, id }) {
                this.$emit('makeThumbnail', { index, id });
            },
        },
    };
</script>

<style lang="scss">
    .image__wrapper {
        input {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            opacity: 0;
            cursor: pointer;
        }

        .upload-block {
            @include row-align-start;
            flex-wrap: wrap;

            box-shadow: $box-shadow-dark;
            border-radius: $br-10;
            position: relative;

            padding: 12px;
            padding-bottom: 2px;
            padding-right: 0;

            min-height: 130px;
            margin-top: 20px;

            &.empty {
                @include row-align-center-justify-center;
            }

            h3 {
                color: $grey-form-label;
                text-align: center;
            }

            .image-card {
                width: 47%;
            }
        }
    }

    @include media($sm) {
        .image__wrapper .upload-block .image-card {
            width: 31%;
        }
    }

    @include media($md) {
        .image__wrapper .upload-block .image-card {
            width: 23.5%;
        }
    }

    @include media($lg) {
        .image__wrapper .upload-block {
            min-height: 230px;

            .image-card {
                width: 31%;
            }
        }
    }

    @include media($xl) {
        .image__wrapper .upload-block .image-card {
            width: 23.3%;
        }
    }
</style>
